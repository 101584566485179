import { Grid, Typography } from '@mui/material'
import React from 'react'

export default function PrivacyNotice() {
  return (
    <>
    <Grid container>
      <Grid item xs={2}></Grid>
      <Grid item xs={8}>
      <Grid container direction='row' justifyContent='center' alignContent='flex-start'>
        <Grid item pt={4}>
          <Typography variant='h3'>
            <b>Aviso de Privacidad</b>
          </Typography>
          <br></br>
          <Typography variant='caption'>
            Fecha de alta en sitio: 31 de marzo de 2023
          </Typography>
        </Grid>
        <Grid item textAlign='left' pt={4}>
          <Typography variant='caption'>
            WWW.CUCOARTS.COM/PRIVACIDAD AVISO DE PRIVACIDAD PARA LA PROTECCIÓN DE DATOS PERSONALES, “CuCo” Esta aviso se ajusta en su totalidad a las leyes mexicanas tal como la LEY DE PROTECCIÓN DE DATOS EN POSESIÓN DE PARTICULARES, su reglamento y en concurrencia al REGLAMENTO GENERAL DE PROTECCIÓN DE DATOS, y sus reformas en la UNIÓN EUROPEA.
            <br></br>
            <br></br>
            I.- IDENTIDAD Y DOMICILIO DEL RESPONSABLE “CuCo” Es una marca comercial bajo las leyes mexicanas que rigen el Instituto Mexicano de la Propiedad Industrial, misma que se encuentra bajo la representación de la sociedad mercantil denominada “CUCO CULTURAL COLLECTIVE MÉXICO RL DE CV , utilizará sus datos personales aquí recabados para BRINDARLE UN BUEN SERVICIO A NUESTROS CLIENTES Y USUARIOS.
            <br></br>
            <br></br>
            II.- DEFINICIONES.- I.- Aviso de Privacidad: Documento físico, electrónico o en cualquier otro formato generado por el responsable que es puesto a disposición del titular, previo al tratamiento de sus datos personales, de conformidad con el artículo 15 de la Ley. II. Bases de datos: El conjunto ordenado de datos personales referentes a una persona identificada o identificable.
            <br></br>
            <br></br>
            III. Consentimiento: Manifestación de la voluntad del titular de los datos mediante la cual se efectúa el tratamiento de los mismos.
            <br></br>
            <br></br>
            IV. Datos personales: Cualquier información concerniente a una persona física identificada o identificable. origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, preferencia sexual.
            <br></br>
            <br></br>
            V. “Instituto”: Instituto Nacional de Acceso a la Información y Protección de Datos, a que hace referencia la Ley Federal de Transparencia y Acceso a la Información Pública Gubernamental.
            <br></br>
            <br></br>
            V. “Instituto”: Instituto Nacional de Acceso a la Información y Protección de Datos, a que hace referencia la Ley Federal de Transparencia y Acceso a la Información Pública Gubernamental.
            <br></br>
            <br></br>
            VI.- Ley: Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
            <br></br>
            <br></br>
            VII.- RGPD.- Reglamento General de Protección de Datos.
            <br></br>
            <br></br>
            VIII. Reglamento: El Reglamento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
            <br></br>
            <br></br>
            IX.- Responsable: Persona física o moral de carácter privado que decide sobre el tratamiento de datos personales.
            X.- Tercero: La persona física o moral, nacional o extranjera, distinta del titular o del responsable de los datos. Con la finalidad de orientar sobre la información recabada al titular de sus datos personales, de acuerdo a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares vigente “LFPDPPP” (art. 15 y 16). A continuación hacemos una declaración en nuestro aviso de privacidad.
            <br></br>
            <br></br>
            <br></br>
            DECLARACIONES:
            <br></br>
            <br></br>
            a) REVOCACIÓN DEL CONSENTIMIENTO DE DATOS PERSONALES: (art. 8) El titular de los datos tiene derecho a revocar en cualquier momento el consentimiento que otorgó al responsable para el tratamiento de sus datos personales. Esto se podrá llevar a cabo solicitándolo en nuestro CORREO ELECTRÓNICO hello@cucoarts.com, o a nuestro domicilio antes citado, mediante la solicitud correspondiente, que se anexa en el siguiente link: FORMULARIO DE EJERCICIO DE DERECHOS ARCO, REVOCACIÓN DEL CONSENTIMIENTO Y/O LIMITACIÓN DE USO O DIVULGACIÓN DE DATOS PERSONALES. (www.cucoarts.com/privacidad).
            <br></br>
            <br></br>
            b) DATOS PERSONALES RECABADOS: (art. 15) Para las finalidades señaladas en el presente aviso de privacidad, podemos recabar sus datos personales de distintas formas de acuerdo al artículo 17: Cuando usted nos lo proporciona directamente (de forma personal), cuando usted visita nuestro sitio de internet o utilizando nuestros servicios en línea (de forma directa), y cuando obtenemos información a través de otras fuentes que están permitidas por la ley (de forma indirecta): A. De forma personal: Cuando usted mismo nos lo proporciona por diversos medios personalmente, como cuando participa en nuestras promociones o nos da información con objeto de que le prestemos un servicio o bien cuando solicita la facturación de un producto o servicio. Los datos pueden ser entre otros: ▪ Nombre ▪ Teléfono particular ▪ Correo electrónico ▪ Domicilio exclusivamente para pedidos ▪ Datos bancarios ▪ Datos de facturación ▪ Para perfiles de “Artistas”; o Nombre; o Teléfono particular; o Correo electrónico; o Domicilio exclusivamente para pedidos; o Datos bancarios; o Datos de facturación; o Perfil artístico; Las Redes sociales: (Facebook, twitter, entre otras) Son una plataforma de comunicación e interconexión dentro de plataformas digitales de los diferentes usuarios, son ajenas a “CuCo” y sus AFILIADAS y por lo tanto, no se encuentran bajo su responsabilidad. Le informamos que en nuestra página web podremos utilizar cookies, de las cuales es posible monitorear su comportamiento como usuario de Internet, así como brindarle un mejor servicio y experiencia de usuario al navegar en nuestra página. B. De forma indirecta: A través de otras fuentes como formulario de datos, redes sociales, los datos pueden ser entre otros: ▪ Nombre ▪ Teléfono ▪ Correo electrónico ▪ Redes Sociales
            <br></br>
            <br></br>
            c) FINALIDADES DEL TRATAMIENTO DE SUS DATOS PERSONALES: (art. 15) Sus datos personales serán utilizados para las siguientes finalidades: • Envío de información; • Envío de promociones; • Seguimiento de servicios; • Seguimiento de logística; • Marketing; • Asegurar el funcionamiento técnico de nuestra red. • Servicio al Cliente • Alta de usuarios; • Alta de Artistas; • Área de Administración; • Seguimiento de dudas y sugerencias de nuestra plataforma;
            <br></br>
            <br></br>
            d) IDENTIDAD Y DOMICILIO DEL RESPONSABLE QUE RECABA LOS DATOS PERSONALES: (art. 16) “CuCo”, y sus AFILIADAS quienes son responsables del tratamiento de sus datos personales,. Puede contactarnos por alguna duda, comentario, queja o sugerencia por medio de nuestro correo electrónico: hello@cucoarts.com
            <br></br>
            <br></br>
            e) LIMITACIÓN DEL USO DE DIVULGACIÓN DE SUS DATOS PERSONALES: (art. 16).- Usted puede limitar ó dejar de recibir mensajes promocionales por teléfono fijo o celulares, así como dejar de recibir correo postal publicitario (que puede contener instrucciones para optar por no participar) y correo electrónico, comunicándose por medio de nuestro correo electrónico: hello@cucoarts.com
            <br></br>
            <br></br>
            f) MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN DE DATOS PERSONALES (ARCO): (art. 16) Usted tiene el derecho de acceder a sus datos personales que poseemos y a los detalles del tratamiento de los mismos, así como rectificarlos en caso de ser inexactos o instruirnos, cancelarlos cuando considere que resulten ser excesivos o innecesarios para la finalidad que justificaron su obtención u oponerse al tratamiento de los mismos para fines específicos. Ingresando a la solicitud correspondiente en el siguiente link: www.cucoarts.com/privacidad
            <br></br>
            <br></br>
            g) TRANSFERENCIA DE DATOS: (art. 36) Sus datos personales pueden ser transferidos y tratados dentro del país, por personas distintas a esta empresa “CUCO”. En este sentido, su información puede ser compartida con: colaboradores de la plataforma y a las autoridades competentes que lo soliciten (art 37). Con las finalidades siguientes: ▪ Logística; ▪ Servicios de paquetería; ▪ Promociones; ▪ Entrega de productos y servicios; ▪ Elevar la calidad de los servicios; ▪ Quejas y/o sugerencias ▪ Seguimiento de servicios. ▪ Revisiones legales; Si usted no manifiesta su oposición mediante la solicitud de revocación para los derechos ARCO (derecho sobre acceso, rectificación, cancelación y oposición), para que sus datos personales sean transferidos, se entenderá que ha otorgado su consentimiento para ello.
            <br></br>
            <br></br>
            h) MODIFICACIÓN AL AVISO DE PRIVACIDAD: (art. 18) Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad, para atención de novedades legislativas o jurisprudenciales, políticas internas, nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos y prácticas del mercado. Estas modificaciones estarán disponibles al público a través de los medios electrónicos correspondientes, a los correos electrónicos que nos haya proporcionado y por medio de nuestra web http://www.cucoart.com/
            <br></br>
            <br></br>
            i) OBTENCIÓN DE DATOS PERSONALES SENSIBLES: El titular declara que tal vez proporcionó datos sensibles, al momento de establecer su compra, ya que marca el estatus social y económico actual. Los datos sensibles son aquellos datos personales íntimos o cuya utilización debida o indebida pueda dar origen a discriminación o un riesgo grave para este. Si usted no manifiesta su oposición mediante la solicitud de revocación para los derechos ARCO, para que sus datos personales se entienda que ha otorgado su consentimiento para ello.
            <br></br>
            <br></br>
            j) PROTECCIÓN DE DATOS CONFORME AL REGLAMENTO GENERAL DE PROTECCIÓN DE DATOS, PARA USUARIOS RESIDENTES EN LA UNIÓN EUROPEA. La protección de las personas físicas en relación con el tratamiento de datos personales es un derecho fundamental. El artículo 8, apartado 1, de la Carta de los Derechos Fundamentales de la Unión Europea («la Carta») y el artículo 16, apartado 1, del Tratado de Funcionamiento de la Unión Europea (TFUE) establece que toda persona tiene derecho a la protección de los datos de carácter personal que le concierne. • Los datos personales que recopilamos para proporcionar ese servicio, cómo se usa y con quién se comparte. • ¿Cómo recopilamos sus datos personales? Cuando se registra en la página web: cuando se registra en el Servicio de “CuCo” recopilamos ciertos datos personales para que pueda utilizar de la plataforma de “CuCo”, como su dirección de correo electrónico, perfil artístico, nombre completo, ciertas veces su domicilio fiscal y datos de facturación.
            <br></br>
            <br></br>
            El presente “AVISO DE PRIVACIDAD”, sus especificaciones, cumplen con los lineamientos REGLAMENTO GENERAL DE PROTECCIÓN DE DATOS, PARA USUARIOS RESIDENTES EN LA UNIÓN EUROPEA. QUEJAS Y DENUNCIAS POR EL TRATAMIENTO INDEBIDO DE SUS DATOS PERSONALES: Si usted considera que su derecho de protección de datos personales ha sido lesionado por alguna conducta de nuestros empleados o de nuestras actuaciones o respuestas, presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en Ley Federal de Protección de Datos Personales en Posesión de los Particulares, podrá interponer la queja o denuncia correspondiente ante el INAI, para mayor información visite www.inai.org.mx Cualquier duda en materia de privacidad de datos que le competa a nuestra empresa, por favor envíe un correo electrónico a hello@cucoarts.com
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={2}></Grid>
      </Grid>
    </Grid>
      
    </>
  )
}
